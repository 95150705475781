import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Nutrient, OcrNutritionFacts } from 'src/app/Models/OcrNutritionFacts';
import { ScanBarcodeResult } from 'src/app/scan-barcode/models/scanBarCodeResult';
import { RestApiService } from 'src/app/services/RestApiService.service';

@Component({
  selector: 'app-image-ocrdata',
  templateUrl: './ocrdata.component.html',
  styleUrls: ['./ocrdata.component.css']
})
export class ImageOcrDataComponent implements OnInit {
  showAddPnsButton: boolean = false;

  isIpadVertical = window.innerWidth < window.innerHeight;
  @Input() ocrRawResponse!: string;
  @Input()
  ocrNutritionFacts!: OcrNutritionFacts;
  @Input() nutrientTypes!: Nutrient;

  @Input()
  gtinCode: number = -1;

  @Output()
  onSaveDraftOcrNutritionFacts = new EventEmitter<OcrNutritionFacts>();

  @Output()
  onAddToPnms = new EventEmitter();

  @Output()
  onScanNewItem = new EventEmitter();
  @Output() nutritionFactChanged = new EventEmitter();

  @Output() changedDevicePostion = new EventEmitter();

  @Output() compareItems = new EventEmitter();
  disableAddToPnms: boolean= false;


  constructor(private restApiService: RestApiService, private router: Router) {

  }

  ngOnInit(): void {    
    this.showAddPnsButton = this.restApiService.showAddPnsButton;
   
  }

  onChangedDevicePostion(event: boolean): void {
    this.isIpadVertical == event;
    this.changedDevicePostion.emit(event);
  }

  saveDraftOcrData() {
    this.onSaveDraftOcrNutritionFacts.emit(this.ocrNutritionFacts);
  }

  compare() {
    this.compareItems.emit();
  }

  addDataToPnms() {
    this.onAddToPnms.emit();
    this.disableAddToPnms = true;
  }

  scanNewItem() {
    this.onScanNewItem.emit();
  }

  onNutritionFactChanged() {
    //  this.hasNutritionFactChanges = true;
    this.nutritionFactChanged.emit();
  }

}


