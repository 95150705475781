<div>
    <app-image-ocr-nutrition-fact *ngIf="ocrNutritionFacts" [ocrNutritionFacts]="ocrNutritionFacts"
                                  [nutrientTypes]="nutrientTypes"
                                  (isIpadVertical)="onChangedDevicePostion($event)"
                                  (nutritionFactChanged)="onNutritionFactChanged()"
                                  [ocrRawResponse]="ocrRawResponse">
    </app-image-ocr-nutrition-fact>
    <!-- <hr> -->
    <div class="mt-auto align-self-end action-buttons" style=" padding-left:40%; text-align: right" *ngIf="!isIpadVertical">

      <div style="display: flex; justify-content: space-between; position: fixed; bottom: 3%">
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 120px;" *ngIf="!showAddPnsButton" class="btn btn-secondary buttonsuccess" (click)="saveDraftOcrData()">Save Draft</button>
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 140px;" class="btn btn-secondary buttonsuccess" (click)="scanNewItem()">Scan New Item</button>
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 120px;" *ngIf="!showAddPnsButton" class="btn btn-secondary buttonsuccess" (click)="compare()">Compare</button>
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 170px;" *ngIf="showAddPnsButton" [disabled]="disableAddToPnms" class="btn btn-secondary buttonsuccess" (click)="addDataToPnms()">Add GTIN to PNMS</button>
      </div>


      <!--<button *ngIf="!showAddPnsButton" class="btn btn-secondary buttonsuccess" (click)="saveDraftOcrData()">Save Draft</button>
      <button class="btn btn-secondary buttonsuccess" (click)="scanNewItem()">Scan New Item</button>
      <button *ngIf="!showAddPnsButton" class="btn btn-secondary buttonsuccess" (click)="compare()">Compare</button>
      <button *ngIf="showAddPnsButton" [disabled]="disableAddToPnms" class="btn btn-secondary buttonsuccess" (click)="addDataToPnms()">Add GTIN to PNMS</button>-->
    </div>
    <div class="button-container" style="text-align: right; padding-left: 40%;" *ngIf="isIpadVertical">
      <div style="position: fixed; bottom: 3%;display: flex; justify-content: space-between;">
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 120px;" *ngIf="!showAddPnsButton" class="btn btn-secondary buttonsuccess" (click)="saveDraftOcrData()">Save Draft</button>
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 140px;" class="btn btn-secondary buttonsuccess" (click)="scanNewItem()">Scan New Item</button>
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 120px;" *ngIf="!showAddPnsButton" class="btn btn-secondary buttonsuccess" (click)="compare()">Compare</button>
        <button style="height: 38px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 170px;" *ngIf="showAddPnsButton" [disabled]="disableAddToPnms" class="btn btn-secondary buttonsuccess" (click)="addDataToPnms()">Add GTIN to PNMS</button>
      </div>
    </div>
  </div>
